<template>
  <div>
    <interval-date-picker
      :year="false"
      @date-updated="date = $event"
      @end-date-updated="endDate = $event"
      @interval-updated="interval = $event"
      @picked="getAuditReceipts({ ...$event, ...$route.query})"
    />

    <v-data-table
      :loading="isLoading"
      :items="items"
      :headers="headers"
      :header-props="{ sortByText: 'ソート' }"
      :footer-props="{ itemsPerPageText: '行/ページ:' }"
      :sort-by="['createdAt']"
      :sort-desc="[true]"
      no-data-text="データがありません"
    >
      <template #loading>
        <v-skeleton-loader type="table" />
      </template>

      <template #[`item.action`]="{item}">
        <v-chip
          :color="actionColor[item.action]"
          :class="`${actionColor[item.action]}--text`"
          class="v-chip-light-bg"
        >
          {{ actionText[item.action] }}
        </v-chip>
      </template>

      <template #[`item.createdAt`]="{item}">
        <span class="text-no-wrap">{{ dateTime(item.createdAt) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { ref, inject, computed } from '@vue/composition-api'
import AuditReceiptApi from '@/api/v2/audit/Receipt'
import IntervalDatePicker from '@/views/components/util/filter/IntervalDatePicker.vue'
import useCompInit from '@/views/composable/useCompInit'
import useQueryString from '@/views/composable/useQueryString'
import useDateFormat from '@/views/composable/useDateFormat'

export default {
  components: {
    IntervalDatePicker,
  },
  setup() {
    const { route } = useQueryString()
    const { isLoading } = useCompInit()
    const currentClubMeta = inject('currentClubMeta', { today: new Date().toISOString().substr(0, 10) })

    const date = ref(route.value.query.date || currentClubMeta.today)
    const interval = ref(Number(route.value.query.interval) || 0)
    const endDate = ref(route.value.query.endDate)
    const auditReceipts = ref([])

    const { dateTime } = useDateFormat()

    const items = computed(() => {
      return auditReceipts.value.map(auditReceipt => {
        return auditReceipt.attributes
      })
    })

    const getAuditReceipts = async (params = null) => {
      isLoading.value = true
      const res = await AuditReceiptApi.getAuditReceipts({ ...params })

      if (res?.data?.data) {
        auditReceipts.value = res.data.data
      }
      isLoading.value = false
    }

    return {
      // data
      isLoading,
      date,
      interval,
      endDate,
      auditReceipts,
      headers: [
        {
          text: 'アクション',
          value: 'action',
        },
        {
          text: '実行者',
          value: 'userName',
        },
        {
          text: '伝票No.',
          value: 'auditableId',
        },
        {
          text: '内容',
          value: 'auditedChanges',
          sortable: false,
        },
        {
          text: 'ログ日時',
          value: 'createdAt',
        },
      ],
      actionText: {
        create: '新規作成',
        update: '更新',
        destroy: '削除',
      },
      actionColor: {
        create: 'success',
        update: 'warning',
        destroy: 'error',
      },

      // computed
      items,

      // methods
      getAuditReceipts,
      dateTime,
    }
  },
}
</script>
