import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getAuditReceipts({ date, interval, endDate = null }) {
    return axiosIns.get(
      '/v2/audit/receipts',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
    )
  },
}
